.tag_container {

  .tag_ul_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    border-bottom: 1px solid black;
    margin: 0;

    li:before {
      content: none;
    }

    padding: 0;
    list-style-type: none;
    line-height: 1.15;

    .tag_items {
      display: inline-block;
      padding: 6px 8px;
      cursor: pointer;
      margin-right: 8px;
      border: solid 1px #cfd6dc;
      font-weight: 300;
      color: #4d545a;

      .icon{
        margin-left: 8px;
        margin-top: -4px;
      }
    }

    .tag_input {
      flex: 1;
      min-width: 200px;
      outline: none;
      border: none;
      line-height: 3;
    }

    .tag_input::placeholder {
      color: #4d545a;
      opacity: 1;
    }

    .tag_input:-ms-input-placeholder {
      color: #4d545a;
    }

    .tag_input::-ms-input-placeholder {
      color: #4d545a;
    }
  }
}

.error {
  .tag_ul_container {
    border-bottom: 1px solid #e62336;

    svg path {
      fill: #4d545a !important;
    }
  }
}





