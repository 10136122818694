.range_select {
  .emankit__input_div {
    width: 120px;
  }
}

.emankit__layout__wrapper {
  display: flex;
  justify-content: center;

  &_content {
    width: 100% !important;
    max-width: 1200px;
  }
}
