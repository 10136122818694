.error {
  .form-select {
    border-bottom: 1px solid #e62336 !important;
  }

  .form-select-indicator path {
    stroke: #e62336 !important;
  }
}

.Select.is-open {
  z-index: 1000 !important;
}
